import { PageProps } from 'gatsby'

import { FC } from 'react'

import { withPage } from '~/components/page'
import LeadDetailView from '~/views/leadDetail'

interface Props extends PageProps {
	params: {
		id: string
	}
}

const LeadDetailPage: FC<Props> = props => {
	return <LeadDetailView leadId={props.params.id} />
}

export default withPage(LeadDetailPage, {
	public: false,
	name: 'Lead Details',
})
